

import startRoutes from "./start-router";
import impressumRoutes from "./impressum-router";
import datenschutzRoutes from "./datenschutz-router";

const routes = [ 
                ...startRoutes,
                ...impressumRoutes,
                ...datenschutzRoutes,
            ];

export default routes;
