<template>
  <div>
    <!--   <router-view v-slot="{ Component }">
    <transition
      :enter-active-class="`animate__animated animate__${$route.meta.enterTransition}`"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <component :is="Component" :key="$route.path"></component>
    </transition>
    </router-view>-->
    <router-view></router-view>
    <widget-container-modal />
  </div>
</template>

<script>
//import VueCookies from "vue-cookies";
import { container } from "jenesius-vue-modal";
//import { openModal } from "jenesius-vue-modal";
//import { mapState } from "vuex"







export default {
  name: "App",
  computed: {
    token() {
      return this.$store.getters.token;
    },
    
  },
  components: {
    WidgetContainerModal: container,
  },
  methods: {
    
  },
  created() {
    
    
  },
  mounted() {
    
  },
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~animate.css/animate.min.css";

p{
  padding: 0;
  margin: 0;  
  font-size: 22px;
}
h1,h2{
  color:rgb(2, 2, 117);
}
h1 {
  text-transform: uppercase;
}
.headerLogo{
  max-width: 10%;
}
.titelLogo{
  max-width: 40%;
  border-style: solid;
  border-width: 5px;
  border-color: rgb(2, 2, 117);
}
.telefonlink{
  text-decoration: none;
  color:rgb(2, 2, 117);
}

.title{
  padding-bottom: 5px;
  padding-top: 10px;
}
.adresse{
  padding: 20px;
  font-weight: bolder;
}

.email{
text-decoration: none;
color:rgb(2, 2, 117);
}

.heidornline{
  border-bottom: 2px  dotted rgb(2, 2, 117);
}
.heidornlineTop{
  border-top: 2px  dotted rgb(2, 2, 117); 
}

.nav {
  background-color: rgb(255, 255, 255);
   color: black !important;
   
}
.titelBackground {
  background-image: url("assets/background.jpg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #000;
  padding-left: 0px;
  padding-right: 0px;
  min-height: 1200px;
}

.bildZentriert{
  border-radius: 18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
.Textblock{
  background-color: rgba(255, 255, 255, 0.767);
}
.absatzEinblenden {
    animation: einblenden 2.5s;
    -moz-animation: einblenden 2.5s; /* Für Firefox */
    -webkit-animation: einblenden 2.5s; /* Für Safari und Chrome */
    -o-animation: einblenden 2.5s; /* Für Opera */
}
@keyframes einblenden {
    from { opacity:0; }
    to { opacity:1; }
}

@-moz-keyframes einblenden { /* Für Firefox */
    from { opacity:0; }
    to { opacity:1; }
}

@-webkit-keyframes einblenden { /* Für Safari und Chrome */
    from { opacity:0; }
    to { opacity:1; }
}

@-o-keyframes einblenden { /* Für Opera */
    from { opacity:0; }
    to { opacity:1; }
}
</style>
