<template #default>
  <div>
    <div class="container-fluid titelBackground">
      <div class="row justify-content-center" ref="Logo" id="Logo">
        <div class="col-md-12 text-center pt-5">
          <img src="../assets/LOGO2.png" class="img-fluid  titelLogo" alt="..." />
        </div>
      </div>
      <div
        class="row justify-content-center pt-5"
        ref="Prosa"
        id="Prosa"
        v-bind:class="{ absatzEinblenden: ProsaIsVisible == true }"
      >
        <div class="col-md-8 text-center p-5 Textblock">
          <h1>
            Wir sind spezialisiert auf die Produktion von Einzelstücken, Mustern und
            Kleinserien
          </h1>
          <h3>
            Mithilfe modernster Fertigungsmethoden und Ausrüstung ist die HEIDORN GmbH
            in der Lage Einzelstücke, Muster und Kleinserien effizient, schnell und
            kostengünstig herzustellen. Damit ist die HEIDORN GmbH der ideale Partner
            für besonderen Anforderungen.
          </h3>
        </div>
      </div>
      <div
        class="row justify-content-center pt-5"
        ref="Carousel"
        id="Carousel"
        v-bind:class="{ absatzEinblenden: CarouselIsVisible == true }"
      >
        <div class="col-md-8 text-center pt-5 Textblock">
          <carouselWrapProdukte></carouselWrapProdukte>
        </div>
      </div>
      <div
        class="row justify-content-center pt-5"
        ref="Textblock"
        id="Textblock"
        v-bind:class="{ absatzEinblenden: TextblockIsVisible == true }"
      >
        <div class="col-md-8 text-center p-5 Textblock">
          <h3>
            Wir sind spezialisiert auf die Produktion von Einzelstücken, Mustern und
            Kleinserien
          </h3>
          <p>
            Seit mehr als 10 Jahren ist unser Unternehmen ein Begriff für
            Qualität und Zuverlässigkeit bei der Produktion von Einzelstücken, Mustern sowie in der Kleinserien-Fertigung.
            Langjährige Erfahrung und Kompetenz bei der Bearbeitung von Einzelstücken als
            auch Kleinserien, aus verschiedensten Werkstoffen sind unsere Stärken. Durch die
            langjährige Erfahrung sind wir in der Lage auch schwierigsten
            Anforderungen gerecht zu werden.
          </p>
        </div>
      </div>
      <div
        class="row justify-content-center pt-5"
        ref="Adressblock"
        id="Adressblock"
        v-bind:class="{ absatzEinblenden: AdressblockIsVisible == true }"
      >
        <div class="col-md-8 text-center p-5 Textblock">
          <h3>Informieren Sie sich über die Möglichkeiten für Sie!</h3>
          <h2>Heidorn GmbH</h2>
          <h2>Breitscheitstraße 7a</h2>
          <h2 class="pb-5">07407 Rudolstadt</h2>
          <h2>
            <a title="Telefon" href="tel:036741189160" target="_blank" rel="noopener" class="telefonlink">
              <i class="fas fa-phone pe-3"></i>
            </a>
            <a href="tel:036724892626" class="telefonlink">03672 489 2626</a>        
          </h2><h2 class="pb-5">
            <a title="Telefon" href="tel:01719971998" target="_blank" rel="noopener" class="telefonlink">
              <i class="fas fa-phone pe-3"></i>
            </a>
            <a href="tel:01719971998" class="telefonlink">0171 997 1 998</a>
            
          </h2>
          <h2 class="pb-5">
            <a href="mailto:heidorn-cnc@gmx.de" class="telefonlink pb-5"
              >heidorn-cnc@gmx.de</a
            >
          </h2>
        </div>
      </div>
      <div class="center pt-5">
        <img :src="getImgUrl('b10')"  v-bind:alt="pic"  class="bildZentriert" style="background-color: white;"/>
      </div>
      <div
        class="row justify-content-center mt-5  Textblock heidornlineTop"
        ref="Footer"
        id="Footer"
        v-bind:class="{ absatzEinblenden: FooterIsVisible == true }"
      >
        <div class="col-2">
          <router-link to="/Impressum" class="nav-link">Impressum</router-link>
        </div>
        <div class="col-2 ">
          <router-link to="/Datenschutz" class="nav-link">Datenschutz</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useElementVisibility } from "@vueuse/core";
import { ref } from "vue";
import carouselWrapProdukte from "../components/carouselWrapProdukte.vue";

export default {
  name: "Start",

  setup() {
    const Logo = ref(null);
    const LogoIsVisible = useElementVisibility(Logo);
    const Prosa = ref(null);
    const ProsaIsVisible = useElementVisibility(Prosa);
    const Carousel = ref(null);
    const CarouselIsVisible = useElementVisibility(Carousel);
    const Footer = ref(null);
    const FooterIsVisible = useElementVisibility(Footer);
    const Textblock = ref(null);
    const TextblockIsVisible = useElementVisibility(Textblock);
    const Adressblock = ref(null);
    const AdressblockIsVisible = useElementVisibility(Adressblock);

    return {
      Adressblock,
      AdressblockIsVisible,
      Textblock,
      TextblockIsVisible,
      Footer,
      FooterIsVisible,
      Carousel,
      CarouselIsVisible,
      Prosa,
      ProsaIsVisible,
      Logo,
      LogoIsVisible,
    };
  },

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    carouselWrapProdukte,
  },
  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.jpg$/);
      console.log(images);
      return images("./" + pet + ".jpg");
    },
  },
  computed: {},
};
</script>

<style scoped></style>
